import React, { useState, useEffect } from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  styled,
  Box,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";
import { AddArticleImg, sendIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import Compressor from "compressorjs";
import { Canvg } from "canvg";
import * as Yup from "yup";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "./../../hooks/hook-form/FormProvider";
import { createCategory, editCategory } from "../../slices/helpdesk";
import Input from "../Input";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import constants from "../../utilities/constants";

const AddArticle = ({ id, open, handleClose, data }) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const { createCategoryStatus, editCategoryStatus } = useSelector(
    (state) => state.helpdesk
  );

  const showSnackbar = (data) => {
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  const defaultValues = {
    name: "",
    description: "",
    color: "",
    translations: constants.LANGUAGES.map((lang) => ({
      name: "",
      description: "",
      language: lang.value,
    })),
  };

  const addArticleSchema = Yup.object().shape({
    name: Yup.string()
      .strict(false)
      .trim()
      .min(2, "Must be longer than 2 characters")
      .required("name is required"),
    description: Yup.string()
      .strict(false)
      .trim()
      .min(2, "Must be longer than 2 characters")
      .required("description is required"),
    color: Yup.string().notRequired(),
    // translations: translationsSchema,
  });

  const [logoFile, setLogoFile] = useState();

  const methods = useForm({
    resolver: yupResolver(addArticleSchema),
    defaultValues,
  });

  const { user } = useSelector((state) => state.user);
  const [value, setValue] = useState(constants.LANGUAGES[0].value);

  const handleChangeTabs = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (data) {
      methods.setValue("name", data?.name);
      methods.setValue("description", data?.description);
      methods.setValue(
        "translations",
        constants.LANGUAGES.map((lang) => {
          return data?.translations?.find((el) => el?.language === lang.value)
            ? data?.translations?.find((el) => el?.language === lang.value)
            : { name: "", description: "", language: lang.value };
        })
      );
      if (data?.color && data?.color !== "") {
        methods.setValue("color", data?.color);
      }
      if (data?.icon) setLogoFile(data?.icon);
    }
  }, [data]);

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    if (file?.type?.includes("gif")) {
      if (file.size <= 5 * 1024 * 1024) {
        setLogoFile(file);
      } else {
        showSnackbar({
          variant: "warning",
          message: t("Please try to upload file with 5MB size or less"),
        });
      }
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          if (file.type === "image/svg+xml") {
            const reader = new FileReader();
            reader.onload = async (event) => {
              const svgContent = event.target.result;
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              try {
                const v = Canvg.fromString(ctx, svgContent);

                await v.render();

                canvas.toBlob((blob) => {
                  if (blob.size <= 5 * 1024 * 1024) {
                    const pngFile = new File(
                      [blob],
                      file.name.replace(".svg", ".png"),
                      { type: "image/png" }
                    );
                    setLogoFile(pngFile);
                  } else {
                    showSnackbar({
                      variant: "warning",
                      message: t(
                        "Please try to upload file with 5MB size or less"
                      ),
                    });
                  }
                }, "image/png");
              } catch (error) {
                console.error("Error converting SVG to PNG:", error);
                showSnackbar({
                  variant: "error",
                  message: t("Error converting SVG to PNG"),
                });
              }
            };
            reader.readAsText(compressedResult);
          } else {
            if (compressedResult.size <= 5 * 1024 * 1024) {
              setLogoFile(compressedResult);
            } else {
              showSnackbar({
                variant: "warning",
                message: t("Please try to upload file with 5MB size or less"),
              });
            }
          }
        },
      });
    }
  };

  const onSubmit = async (values) => {
    try {
      if (data) {
        values?.translations?.find(
          (el) =>
            (el?.name?.trim() !== "" && el?.description?.trim() === "") ||
            (el?.description?.trim() !== "" && el?.name?.trim() === "")
        )
          ? showSnackbar({
              variant: "error",
              message: t(
                "name or description must be not empty if one of them is available. check all languages please."
              ),
            })
          : dispatch(
              editCategory({
                websiteID: user?.websiteID,
                name: values.name,
                description: values.description,
                categoryId: data?._id,
                color: values.color !== "" ? values.color : undefined,
                file:
                  logoFile && typeof logoFile !== "string"
                    ? logoFile
                    : undefined,
                translations: values?.translations?.filter(
                  (el) => el?.name !== ""
                ),
              })
            ).then((res) => {
              setValue(constants.LANGUAGES[0]);
              setLogoFile(null);
              if (res.meta.requestStatus === "fulfilled") {
                reset(defaultValues);
                showSnackbar({
                  variant: "success",
                  message: t("Article Category Successfully Updated"),
                });
                handleClose(id);
              } else {
                showSnackbar({
                  variant: "error",
                  message: t(res.error.message),
                });
              }
            });
      } else {
        values?.translations?.find(
          (el) =>
            (el?.name?.trim() !== "" && el?.description?.trim() === "") ||
            (el?.description?.trim() !== "" && el?.name?.trim() === "")
        )
          ? showSnackbar({
              variant: "error",
              message: t(
                "name or description must be not empty if one of them is available. check all languages please."
              ),
            })
          : dispatch(
              createCategory({
                websiteID: user?.websiteID,
                name: values.name,
                description: values.description,
                color: values.color !== "" ? values.color : undefined,
                file: logoFile ? logoFile : undefined,
                translations: values?.translations?.filter(
                  (el) => el?.name !== ""
                ),
              })
            ).then((res) => {
              setValue(constants.LANGUAGES[0]);
              setLogoFile(null);
              if (res.meta.requestStatus === "fulfilled") {
                reset(defaultValues);
                showSnackbar({
                  variant: "success",
                  message: t("Article Category successfully created"),
                });
                handleClose(id);
              } else {
                showSnackbar({
                  variant: "error",
                  message: t(res.error.message),
                });
              }
            });
      }
    } catch (err) {
      showSnackbar({
        variant: "error",
        message: err.message,
      });
    }
  };

  const { handleSubmit, reset, formState, register } = methods;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(id);
        reset(defaultValues);
        setLogoFile(null);
        setValue(constants.LANGUAGES[0]);
      }}
      className="info-modal"
    >
      <LazyLoadImage
        src={AddArticleImg}
        className="assign-icon"
        alt="AddArticleImg"
      />
      <DialogTitle className="assign-operator-title">
        {t(data ? "Edit Category" : "Create a new Category")}
      </DialogTitle>
      <DialogContentText className="assign-operator-text">
        {t(
          "In order to push your article to helpdesk you need to setup all these preferences"
        )}
      </DialogContentText>
      <DialogContent
        style={{ padding: "20px 24px 5px 24px" }}
        className="add-category"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div id="select-category">
            <Input
              label={t("Name")}
              placeholder={t("Name")}
              name="name"
              errors={formState.errors}
              register={register}
            />
            <Input
              label={t("Description")}
              placeholder={t("Description")}
              name="description"
              errors={formState.errors}
              register={register}
            />
            <Input
              label={t("Button Color")}
              placeholder={t("Button Color")}
              name="color"
              errors={formState.errors}
              register={register}
              type="color"
            />
            <div className="update-logo-container">
              <label className="icon-logo">Icon</label>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                className="upload-logo"
                style={{ color: "#FDC423", border: "1px dashed #FDC423" }}
                onChange={handleUploadFile}
              >
                {logoFile ? (
                  <img
                    src={
                      typeof logoFile === "string"
                        ? logoFile
                        : URL.createObjectURL(logoFile)
                    }
                    alt="logo"
                    className="logo-container-config"
                  />
                ) : (
                  t("Upload an icon")
                )}
                <VisuallyHiddenInput type="file" accept="image/*" />
              </Button>
            </div>
            <div style={{ marginTop: "19px" }}>
              <Box sx={{ mt: "9px", mb: "9px" }}>
                <Tabs value={value} onChange={handleChangeTabs}>
                  {constants.LANGUAGES.map((el, index) => (
                    <Tab value={el.value} label={el.label} key={index} />
                  ))}
                </Tabs>
              </Box>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "9px" }}
              >
                <span className="translated-title">Translated name</span>
                <Controller
                  name={`translations[${methods
                    ?.watch("translations", defaultValues.translations)
                    .findIndex((el) => el.language === value)}].name`}
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={
                        methods
                          .watch("translations", defaultValues.translations)
                          .find((el) => el.language === value)?.name || ""
                      }
                      placeholder={t("Translated name")}
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                        const translations = methods.getValues("translations");
                        const index = translations.findIndex(
                          (el) => el.language === value
                        );
                        if (index >= 0) {
                          translations[index].name = e.target.value;
                          methods.setValue("translations", translations);
                        }
                      }}
                      helperText={
                        formState.errors?.translations?.[
                          methods
                            .watch("translations", defaultValues.translations)
                            .findIndex((el) => el.language === value)
                        ]?.name?.message
                      }
                      error={Boolean(
                        formState.errors?.translations?.[
                          methods
                            .watch("translations", defaultValues.translations)
                            .findIndex((el) => el.language === value)
                        ]?.name?.message
                      )}
                      InputProps={{
                        className: "create-bot-question-TextField",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div style={{ marginTop: "19px" }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "9px" }}
              >
                <span className="translated-title">Translated description</span>
                <Controller
                  name={`translations[${methods
                    ?.watch("translations", defaultValues.translations)
                    .findIndex((el) => el.language === value)}].description`}
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={
                        methods
                          .watch("translations", defaultValues.translations)
                          .find((el) => el.language === value)?.description ||
                        ""
                      }
                      placeholder={t("Translated description")}
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e);
                        const translations = methods.getValues("translations");
                        const index = translations.findIndex(
                          (el) => el.language === value
                        );
                        if (index >= 0) {
                          translations[index].description = e.target.value;
                          methods.setValue("translations", translations);
                        }
                      }}
                      helperText={
                        formState.errors?.translations?.[
                          methods
                            .watch("translations", defaultValues.translations)
                            .findIndex((el) => el.language === value)
                        ]?.description?.message
                      }
                      error={Boolean(
                        formState.errors?.translations?.[
                          methods
                            .watch("translations", defaultValues.translations)
                            .findIndex((el) => el.language === value)
                        ]?.description?.message
                      )}
                      InputProps={{
                        className: "create-bot-question-TextField",
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <DialogActions
            className="assign-operator-btns"
            style={{ marginTop: "19px" }}
          >
            <Button
              className="assign-operator-cancel-btn"
              onClick={() => {
                reset(defaultValues);
                handleClose(id);
                setValue(constants.LANGUAGES[0]);
              }}
            >
              {t("Cancel")}
            </Button>
            <button
              className="assign-operator-change-btn"
              color="primary"
              type="submit"
              disabled={
                createCategoryStatus === "loading" ||
                editCategoryStatus === "loading"
              }
            >
              <LazyLoadImage src={sendIcon} style={{ marginRight: "5px" }} />
              {t(data ? "Edit" : "Create")}
            </button>
          </DialogActions>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AddArticle;
